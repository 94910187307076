<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>
// 引入导航栏组件
import NavBar from "@/components/NavBar";
export default {
  name: "App",
  // 挂载组件
  components: {
    NavBar,
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style >
@import '@wangeditor/editor/dist/css/style.css';

* {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}
body {
  background: #eff2f6;
}
a{
  text-decoration: none;
}

.w-e-full-screen-container{
  z-index: 999;
}

.container img{
    width: 100%;
    display: block;
}


</style>
