import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/details/:id',
    name: 'details',
    component: () => import('../views/details.vue')
  },
  {
    path: '/Login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/search/',
    name: 'search',
    props: true,
    component: () => import('../views/search.vue')
  },
  {
    path: '/search/:val',
    name: 'search2',
    props: true,
    component: () => import('../views/search.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user.vue'),
    meta: {
      isAuth: true,
    },
    redirect: '/user/myInfo',
    children: [
      // 首页
      {
        path: 'myInfo',
        meta: {
          activeNumber: 0,
          isAuth: true,
        },
        component: () => import('../views/user/index.vue')
      },
      // 我的会员
      {
        path: 'store',
        meta: {
          activeNumber: 1,
          isAuth: true,
        },
        component: () => import('../views/user/store.vue')
      },
      // 我的收藏
      {
        path: 'myfav',
        meta: {
          activeNumber: 2,
          isAuth: true,
        },
        component: () => import('../views/user/myfav.vue')
      },
      // 我的文章
      {
        path: 'myArticle',
        meta: {
          activeNumber: 3,
          isAuth: true,
        },
        component: () => import('../views/user/myArticle.vue')
      },
      // 发布文章
      {
        path: 'write',
        meta: {
          isDirty: false,//编辑状态
          activeNumber: 4,
          isAuth: true,
        },
        component: () => import('../views/user/publish.vue'),

      },
    ]
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


import store from '@/store/index';
import axios from '@/axios/index';
// 在这里定义一个全局的导航守卫
router.beforeEach((to, from, next) => {
  console.log(from.meta.isDirty)
  // 判断是否需要弹出确认框
  if (from.meta.isDirty) {
    // 使用window.confirm弹出确认框
    if (window.confirm('您有未保存的更改，确定要离开吗？')) {

      console.log("关闭之前操作");
      if (store.state.TobeSavedFile.length > 0) {
        // 执行删除
        console.log("大于0执行")
        axios.post("/deleteFiles", { deleteFiles: JSON.stringify(store.state.TobeSavedFile) }).then((res) => {
          console.log(res);
          // 重置空
          store.commit("setSavedFile", [])
        });;

      }
      next()


    } else {
      // 取消导航
      next(false)
    }
  } else {
    next()
  }

  //如果路由需要跳转
  if (to.meta.isAuth) {
    if (store.state.token) {
      next()  //放行
    } else {
      next({path: '/Login'})
    }
  } else {
    // 否则，放行
    next()
  }

})

export default router
