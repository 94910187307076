// api请求路径 管理所有接口
import axios from "./index"



// 登录
// 登录按钮 账号登录
export  const LoginBtn = (params) => axios.post("/login/",params);
// 注册
export  const RegisterBtn = (params) => axios.post("/register/",params);


// 首页
export  const  home = (params) => axios.get("/home",{params});
// 商品详情
export  const  details = (params) => axios.get("/details",{params});
// 随机推送10个商品
export  const  Rand_goods = (params) => axios.get("/Rand_goods",{params});



// 文章分类列表
export  const  typeList = (params) => axios.get("/typeList",{params});

// 富文本图片上传
export  const  image_upload = (params) => axios.post("/image_upload",params);
// 保存，提交文章
export  const  publish = (params) => axios.post("/publish",params);
// 富文本直接删除图片
export  const  deleteFiles = (params) => axios.post("/deleteFiles",params);


// 支付，购买
export  const  payment = (params) => axios.post("/payment",params);
// /store/list
// 收藏列表
export  const  store_list = (params) => axios.get("/store/list",params);


// 收藏
export  const  collect = (params) => axios.post("/collect",params);
// 收藏列表
export  const  collect_list = (params) => axios.get("/collect/list",params);

// 收藏删除
export  const  collect_del = (params) => axios.post("/collect/del",params);



// 用户文章发布列表 
export  const  article_list = (params) => axios.get("/article/list",params);

// 删除文章
export  const  article_del= (params) => axios.post("/article/del",params);

// 用户信息修改
export  const  userinfo_update = (params) => axios.post("/userinfo/update",params);
// 用户信息详情
export  const  userinfo= (params) => axios.get("/userinfo",params);









