import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// 引入ui框架
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false
import { Message } from 'element-ui';
Vue.prototype.$Message = Message;
// main.js
Vue.prototype.$bus = new Vue();

// 引入接口
import * as api from './axios/api'
// 全局设置接口
Vue.prototype.$api = api;
// 引入icon
import './assets/iconfont/iconfont.css'


import VueDOMPurifyHTML from 'vue-dompurify-html';
Vue.use(VueDOMPurifyHTML);


import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css'	//样式

Vue.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
    hljs.highlightBlock(block)    
  })
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
