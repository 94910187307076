<template>
  <div>
   
      <div class="grid-content bg-purple">
        <!-- 图片 -->
        <div class="imgBox">
          <img :src="item.picture" alt=""/>
        </div>
        <!-- 标签 -->
        <div class="tipBox">
          <div class="meta-category"><i class="dot"></i>其他模板</div>
          <div class="meta-category"><i class="dot"></i>{{item.type}}</div>
        </div>
        <!-- 标题 -->
        <div class="entry-title">
          <span>{{item.title}}</span>
        </div>
        <!-- 日期 -->
        <div class="entry-footer">
          <!-- 日期 -->
          <div class="mate-date">
            <span class="iconfont icon-shijian"></span>
            <time datetime="2023-08-14 18:56:44">{{item.create_time}}</time>
          </div>
          <!-- 价格 -->
          <div class="meta-price">
            <span><i class="iconfont icon-31huiyuan"></i>39元会员免费</span>
            <div class="tuijian">推荐</div>
          </div>
        </div>
      </div>
 
  </div>
</template>

<script>
export default {
  name: "ItemCard",
  props:{
    item:Object
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.grid-content {
  background: white;
  border-radius: 5px;
  overflow: hidden;
  width: 280px;
}

.imgBox {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.imgBox img {
  width: 100%;
  display: block;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.imgBox img:hover {
  transform: scale(1.1);
}

/* 标签 */
.tipBox {
  display: flex;
  flex-wrap: wrap;
}

.meta-category:nth-child(1) .dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
}

.meta-category:nth-child(2) .dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #4caf50;
}

.meta-category {
  color: #aaa;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

/* 标题 */
.entry-title {
  padding: 5px;
}

.entry-title span {
  font-size: 16px;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 日期 */
.entry-footer {
  /* background: pink; */
  border-top: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px;
}

.mate-date span,
.mate-date {
  font-size: 14px;
}

.mate-date span {
  padding: 0 3px;
}

.meta-price {
  display: flex;
}

.meta-price > span {
  font-size: 14px;
  color: #fd721f;
}

.meta-price > span > i {
  color: #fd721f;
}

.tuijian {
  margin-left: 5px;
  position: relative;
  display: inline-block;
  padding: 1px 6px;
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  border-radius: 2px;
  line-height: initial;
}

.el-col {
  margin-bottom: 20px;
}
</style>
