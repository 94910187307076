<template>
  <!-- 彩色标题 -->
  <div class="contaiTitleBox">
    <div class="contaiTitle">
      <span>{{title}}</span>
      <div class="HhChTitle" v-if="state">
        <strong>{{t1}}</strong>
        <strong>{{t2}}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props:{
    title:{
        type:String,
        default:"NEWS"
    },
    t1:{
        type:String,
        default:"最新"
    },
    t2:{
        type:String,
        default:"发布"
    },
    state:{
        type:Boolean,
        default:true
    }
  },
  data() {
    return {

    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>


/* 彩色标题 */
.contaiTitle:before,
.contaiTitle:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background: linear-gradient(-90deg, #b108ff 0%, #364cfd 100%);
    border-radius: 50%;
}


.contaiTitle {
    text-align: center;
    position: relative;
    margin-bottom: 38px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1;

}

.contaiTitle span {
    font-family: Futura;
    background: linear-gradient(-125deg, #c000ff 0%, #2954fd 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 49px;
    letter-spacing: 5px;
    display: inline-block;
    font-weight: 700;
    padding-left: 10px;
}

.HhChTitle {
    padding-right: 10px;
}

.HhChTitle strong {
    display: block;
    font-size: 20px;
    background: linear-gradient(-90deg, #b108ff 0%, #364cfd 100%);
    -webkit-background-clip: text;
    color: transparent;
}
</style>



