<template>
  <!-- 导航栏组件 -->
  <div>
    <el-backtop :bottom="60" :right="100">
      <div class="backtop">
        <span class="iconfont icon-fanhuidingbu2"></span>
      </div>
    </el-backtop>

    <!-- 顶栏容器 -->
    <el-header class="headerBox">
      <!-- 导航栏居中 -->
      <div class="container">
        <el-row :gutter="24">
          <!-- 左边 -->
          <el-col :span="8">
            <div class="header_Left">
              <div class="logo">
                <!-- <span class="iconfont icon-laba"></span> -->
                <router-link to="/">
                  <img src="https://cdn.wantuju.com/pngsucai/imgRegular/20210801/d920b8ce-e17c-4c59-b916-dbdedaea52a0%20_49058_wantuju.png" alt="" />
                  </router-link>
               
              </div>
              <!-- 搜索 -->
              <div class="searchBox">
                <div class="weather-search">
                  <input type="text" v-model="value" placeholder="请输入内容..." />
                  
                  <button class="search" title="搜索" @click="search()" >
                    <span class="iconfont icon-sousuoleimu"></span>
                  </button>
          
                </div>
              </div>
            </div>
          </el-col>
          <!-- 右边 -->
          <el-col :span="16">
            <div class="header_Right">
              <!-- 下拉菜单 -->
              <div class="DropdownBox" v-for="(v,index) in DropdownList" :key="index">
                <!-- 菜单选项 -->
                <div class="Dropdownitem">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ v.title }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="item">
                   
                      <!-- <el-dropdown-item v-for="(item,index2) in v.data" :key="index2"
                      :command="item.url">{{item.title}}</el-dropdown-item> -->
                      <el-dropdown-item v-for="(item,index2) in v.data" :key="index2"
                      :command="item.url">{{item.title}}</el-dropdown-item>
                 
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>

              

              <!-- 选项按钮  -->
              <div class="Rightitme">
                <ul>
                
                  <!-- 用户头像 -->
                  <li v-if="$store.state.token">
                    <el-popover trigger="click" placement="bottom">
                      <div class="popoverUserBOx">
                        <!-- 用户信息 -->
                        <div class="popoveruserbox">
                          <div>
                            <img :src="$store.state.UserInfo.avatar" alt="" />
                          </div>
                          <div>
                            <h3>{{$store.state.UserInfo.userName}}</h3>
                            <span>{{$store.state.UserInfo.email}}</span>
                          </div>
                        </div>
                        <div class="popUserBtn">
                          <button @click="$router.push('/user')">
                            查看个人资料
                          </button>
                        </div>
                        <!-- 退出登录按钮 -->
                        <div class="logoutbtn" @click="logoutbtn()">
                          <div><span class="iconfont icon-guanbi"></span>退出</div>
                        </div>
                      </div>
                      <!-- 按钮 -->
                      <div slot="reference">
                        <img :src="$store.state.UserInfo.avatar" alt="" />
                      </div>
                    </el-popover>
                  </li>

                  <li v-else @click="$router.push('/Login')">
                    <img src="@/assets/images/user/tx.png" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-header>
    <!-- 顶部填充 -->
    <div style="width: 100; height: 60px"></div>
  </div>
</template>

<script>

// 引入socket.io
export default {
  name: "NavBar",
  props: {},
  data() {
    return {
      isLogin: true,
      msgevent:false,
      value:"",//搜索内容
      msgData:[
        // {
        //   img:"",
        //   name:"",
        //   value:"",
        //   date:""
        //, }
      ],
      DropdownList:[
        {
          title:"小程序源码",
          data:[
            {
              title:"小程序",
              url:"/search/小程序"
            },
            {
              title:"APP",
              url:"/search/app"
            }
          ]
        },
        {
          title:"网站源码",
          data:[
            {
              title:"网站项目",
              url:"/search/网站"
            },
            {
              title:"vue模板",
              url:"/search/vue"
            },
            {
              title:"html模板",
              url:"/search/html"
            }  
          ]
        },
        {
          title:"其他",
          data:[
            {
              title:"php源码",
              url:"/search/php"
            },
            {
              title:"node源码",
              url:"/search/node"
            },
            {
              title:"uni-app",
              url:"/search/uni-app"
            }
          ]
        },
        {
          title:"定制",
          data:[
            {
              title:"低价订做",
              url:"/"
            }
          ]
        }
      ]
    };
  },
  computed: {},
  methods: {
    // 退出登录按钮
    logoutbtn() {
      this.$confirm("是否退出？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 重置登录状态和用户信息
        this.$store.commit("setUserInfo", {});
        this.$store.commit("setToken", null);
        this.$router.push('/')
        window.location.reload();
      });
    },
    qingkong(){
      this.msgData=[]
    },
    goto(url){
      console.log(url)
      window.location=url
    },
    handleCommand(command){
      window.location=command
    },

      // 搜索
      search(){
        console.log("搜索")
        // this.$bus.$emit('search-content', this.value);
        this.$store.commit('setsearch',this.value)
        this.value=""
        this.$router.push('/search')
      }
    
  },
  mounted() {
    
  },
  created(){
    // this.socketconnection();
  },

};
</script>

<style scoped>
/* 返回顶部 */
.backtop {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #f2f5f6;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  line-height: 40px;
  color: #1989fa;
  
}
.backtop span {
  font-size: 30px;
}

/* 首页 */
.headerBox {
  width: 100%;
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  /* border-bottom: 1px solid #e7e7e9; */
  box-shadow: 0 2px 4px #00000014;
}

.header_Left {
  display: flex;
}

.container {
  width: 1200px;
  /* height: 60px; */
  /* background: pink; */
  margin: 0 auto;
  padding: 10px 0;
}

.logo,
.logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* 搜索样式2 */
.searchBox {
  /* width: 270px; */
  height: 40px;
  /* background: #99a9bf; */
  margin-left: 30px;
}

.weather-search {
  display: flex;
  width: 270px;
  height: 40px;
  border: 1px solid white;
  border-radius: 5px;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  background: #eef0f2;
}

.weather-search input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}

.weather-search input {
  width: 270px;
  height: 32px;
  border: 0;
  font-size: 14px;
  outline: none;
  margin: 0 10px;
  background-color: transparent;
}

.search {
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  min-width: 16px;
  color: #676a79;
}

.search span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  outline: none;
  margin-right: 10px;
  color: #676a79;
}

/* 右边 */

.header_Right {
  display: flex;
  justify-content: flex-end;
}

.DropdownBox {
  height: 40px;
  display: flex;
}

.Dropdownitem {
  padding: 0 30px;
}

/* 选项颜色 */
.el-dropdown-link {
  cursor: pointer;
  color: #676a79;
  line-height: 40px;
  font-size: 16px;
  font-weight: 800;
}

.el-dropdown-link:hover {
  color: #0393da !important;
}

.el-icon-arrow-down {
  font-size: 16px;
  font-weight: 800;
}

.el-dropdown-menu__item {
  padding: 0 30px !important;
}

/* 选项按钮 */
.Rightitme {
  height: 40px;
}

.Rightitme ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.Rightitme ul > li {
  list-style: none;
  line-height: 40px;
  text-align: center;
  height: 40px;
  width: 40px;
  /* background: #eef0f2; */
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.Rightitme ul > li:hover span {
  color: #0c59bd;
  font-size: 20px;
}

.Rightitme ul > li > span {
  font-size: 18px;
  color: #676a79;
  font-weight: 800;
}

.Rightitme ul > li:nth-child(4) {
  background: none;
}

.Rightitme ul > li:nth-child(4) img {
  height: 40px;
  width: 40px;
}

/* 展开子页面 */
/* Popover */
.PopoverBox {
  height: auto;
  /* width:  */
  min-width: 340px;
  /* background: pink; */
}

.Popover-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 2px solid #e7e7e9;
}

.Popover-header h5 {
  font-size: 15px;
}

.Popover-header h5 span {
  font-size: 8px;
  color: #d6293e;
  background: #fbe9eb;
  font-weight: 200;
  padding: 3px 5px;
  border-radius: 3px;
}

.Popover-header span {
  font-size: 14px;
  color: #0393da;
  cursor: pointer;
}

/* 消息列表 */
.list-msg {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* height: 450px; */
  /* background: red; */
  overflow-y: scroll;
  /* 允许垂直滚动 */
  /* overflow-x: hidden; */
}

.list-msg::-webkit-scrollbar {
  display: none;
}

.list-msg ul {
}

.list-msg li {
  list-style: none;
  margin: 15px 0;
}

.msgbox {
  width: 300px;
  height: 75px;
  background: #eef0f2;
  display: flex;
  justify-content: space-between;
  position: relative;
  /* left: 10px; */
  border-radius: 5px;
}

/* 图标或头像 */
.msg-icon {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 5px 0 0 10px;
}

.msg-icon,
.msg-icon img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.msg-conten {
  width: 220px;
  /* background: blue; */
}

/* 名称 */
.msg-conten h4 {
  margin: 5px 0;
  font-weight: 600;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* 内容 */
.msg-conten span {
  margin: 5px 0;
  font-weight: 400;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* 时间 */
.msg-date {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

/* 用户点击头像 */
.popoverUserBOx {
  width: 200px;
  height: 150px;
}

/* 用户信息 */
.popoveruserbox {
  display: flex;
}

.popoveruserbox div:nth-child(1) {
  height: 48px;
  width: 48px;
  padding: 0 15px 0 10px;
}

.popoveruserbox div:nth-child(1) img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.popoveruserbox div:nth-child(2) h3 {
  font-size: 15px;
}

.popoveruserbox div:nth-child(2) span {
  font-size: 12px;
}

/* 按钮 */
.popUserBtn button {
  width: 160px;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
  color: #0f6fec;
  padding: 6.5px 20px;
  border: none;
  background-color: rgba(15, 111, 236, 0.1);
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.popUserBtn button:hover {
  color: white;
  background: #0f6fec;
}

/* 退出登录按钮 */
.logoutbtn {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  background: white;
  line-height: 50px;
  font-size: 15px;
  border-top: 1px solid #e7e7e9;
  border-bottom: 1px solid #e7e7e9;
  cursor: pointer;
}

.logoutbtn div {
  padding-left: 10px;
}

.logoutbtn div span {
  padding: 0 10px;
}

.logoutbtn:hover {
  color: #0393da;
}
</style>
