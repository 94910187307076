import Vue from 'vue'
import Vuex from 'vuex'
// 使用插件保存vuex数据，防止刷新丢失
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    UserInfo: {}, //保存用户对象
    isLogin: false, //登录状态，t登录，f未登录
    token: "",//用户登录令牌
    TobeSavedFile:[],//编辑页面临时上传的图片名称,如果不保存就通过这里名单删除
    searchInput:"",//导航栏搜索内容
  },
  getters: {
  },
  mutations: {
    // 保存token
    setToken(state, data) {
      state.token = data
    },
    // 保存用户信息
    setUserInfo(state, data) {
      state.UserInfo = data
    },
    // 临时上传的图片名称
    setSavedFile(state, data) {
      state.TobeSavedFile = data
    },
      // 导航栏搜索内容
      setsearch(state, data) {
        state.searchInput = data
      },
  },
  actions: {
  },
  modules: {
  },
  // 使用插件保存vuex数据，防止刷新丢失
  plugins: [createPersistedState({
    storage: window.localStorage

  })]
})
