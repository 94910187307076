<template>
  <!-- 容器 -->
  <div>
    <el-container>
      <el-main>
        <div class="mainBox">
          <!-- 公告 -->
          <div class="NoticeBox">
            <el-carousel
              direction="vertical"
              :autoplay="true"
              height="36px"
              indicator-position="none"
            >
              <el-carousel-item v-for="item in NoticeList" :key="item">
                <!-- 循环体 -->
                <div class="NoticeitemBox">
                  <div>
                    <span class="iconfont icon-tixing"></span>
                  </div>
                  <span>{{ item }}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>

          <!-- 轮播图 -->
          <!-- 轮播图 -->
          <div class="sliderBox">
            <!-- 未登录时显示这个 -->
            <div v-if="false">
              <el-row :gutter="24">
                <!-- 左边 -->
                <el-col :span="16">
                  <!-- 轮播图图片 -->
                  <div class="bannerBox">
                    <el-carousel height="330px">
                      <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
                        <div class="bannerImg">
                          <img :src="item" alt="" />
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </el-col>
                <!-- 右边 -->
                <el-col :span="8">
                  <div class="userBox">
                    <!-- 背景图 -->
                    <div class="backgroundImg">
                      <img src="../assets/images/bj-2.png" alt="" />
                    </div>
                    <!-- 头像 -->
                    <div class="userAvatar">
                      <!-- <el-avatar
                        :size="80"
                        src=""
                      ></el-avatar> -->
                      <img src="@/assets/images/user/tx.png" alt="" />
                    </div>
                    <!-- 提示 -->
                    <div class="text-center">
                      <h4>HI！请登录</h4>
                    </div>
                    <!-- 按钮 -->
                    <div class="btnBox">
                      <button @click="$router.push('/Login')">登录</button>
                      <button>注册</button>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!-- 登录时显示这个 -->
            <div >
              <div class="bannerbigBox">
                <el-carousel :interval="4000" type="card" height="330px" :autoplay="true">
                  <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
                    <div class="bannerbigImg">
                      <img :src="item" alt="" />
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>

          <!-- 文章 -->
          <div class="containerBox">
            <!-- 彩色标题 -->
            <Title></Title>
            <!-- 文章内容 -->
            <div class="containerItem">
              <router-link
                v-for="(v, index) in itemList"
                :key="index"
                :to="'/details/' + v.id"
              >
                <!-- 项目卡片组件 -->
                <ItemCard :item="v"></ItemCard>
              </router-link>
            </div>
          </div>

          <!-- 分页 -->
          <div class="paginationBox">
            <el-pagination background layout="prev, pager, next" :total="total"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="current"
            @prev-click="prev"
            @next-click="next">
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import ItemCard from "@/components/ItemCard.vue";
import Title from "@/components/Title.vue";

export default {
  name: "HomeView",
  components: {
    ItemCard,
    Title,
  },
  data() {
    return {
      // 轮播图图片
      bannerImg: [
        "https://www.qumaw.com/tu/11.jpg",
        "https://www.qumaw.com/tu/22.jpg",
        "https://www.qumaw.com/tu/33.jpg",
      ],
      isLogin: false, //登录状态
      // 公告数组
      NoticeList: [
        "欢迎您光临取码网，销售只是起点 服务永无止境！",
      ], //公告列表

      itemList: [], //列表
      currentPage:1,//分页当前页
      total:0,//全部页数
      pageSize:20,//每页多少个
    };
  },
  computed: {},
  methods: {
    current(e){
      console.log("当前页"+e)
        this.getdata(e)
    } ,
    prev(){
      console.log("上一页")
     
    },
    next(){
      console.log("下一页")
 
    },

    // 获取数据
    getdata(num){
      this.$api.home({
        num:num
      }).then((res) => {
      console.log(res);
      this.itemList = res.data.data.list;
      this.total=res.data.data.count
    });
    }

  },
  mounted() {
    this.getdata()
  },
};
</script>

<style scoped src="../css/HomeView.css"></style>
