import axios from 'axios'

import store from '@/store/index';

// let apiUrl='http://localhost:3000/api/v1';
// let apiUrl='http://192.168.1.200:3000/api/v1';
// let apiUrl='http://192.168.43.235:3000/api/v1';
// let apiUrl='http://10.240.206.241:3000/api/v1';
let apiUrl='https://ymapi.lbwnb.cn/api/v1';
axios.defaults.baseURL=apiUrl
axios.defaults.headers={
    'Content-Type': 'application/x-www-form-urlencoded',
     'Accept': 'application/json'
}

// 统一方式
// 请求拦截器 添加请求头
axios.interceptors.request.use(config=>{
  //如果前端访问后端无法保留session才使用true
      // config.withCredentials = true;
    // // 发送请求之前拦截下来，去做请求前准备工作如token
    let token=store.state.token;
    if(token){
        // 把token设置到请求头中
        config.headers['Authorization'] = token
    }
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      // 处理上传文件的请求
    } else {
      // 处理其他请求
    }
   
    // config 为这次发送的信息对象
    return config
},error=>{
    return Promise.reject(error)
})





// 响应拦截器
axios.interceptors.response.use(response => {
  // 正常响应处理
  return response;
}, error => {
  // 错误响应处理
  if (error.response && error.response.status === 401) {
    // 处理登录过期逻辑，比如弹出提示框
    alert('登录已过期，请重新登录');
    window.location='/login'
  }
  return Promise.reject(error);
});


export default axios